<template>
    <div>
        <card-header title="Edit Day" icon="fa-pencil"/>

        <card-body>
            <day-form :day="day"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteDay"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Day?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!day.title || !day.type || !day.day_on" @click="submit"><i class="fas fa-check mr-3"></i>Save Day</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import DayForm from "./DayForm";

    export default {
        props: ['card', 'props'],
        components: {DayForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function() {
            return {
                day: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadDay: function() {
                this.$emit('loading', true);
                this.dirty = null;

                http.get('/api/days/' + this.props.dayId, {force: true}).then(response => {
                    response.data.redate = false;
                    this.day = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/days/' + this.day.id, this.day, {force: true}).then(response => {
                    this.$reloadCard('days', {tripId: this.props.tripId});
                    this.$reloadCard('day', {dayId: this.props.dayId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteDay() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/days/' + this.props.dayId).then(response => {
                        this.$reloadCard('days', {tripId: this.props.tripId});
                        this.$reloadCard('day', {dayId: this.props.dayId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.dayId': function() {
                this.dirty = null;
                this.loadDay();
            },
            'day': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this day? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadDay();
        }
    };
</script>
